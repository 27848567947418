@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700&display=swap');

body {
  background-color: rgb(248, 214, 208);
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.kana-item {
  padding: 10px 20px;
  user-select: none;
  transition: color 200ms;
}

#current-kana {
  font-size: 10rem;
}

#test-result {
  min-height: 5rem;
}

// focus window idea - to do
